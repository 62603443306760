<template>
  <div class="body">
    <img src="@/assets/img/guide/deptSpread/bg.png" alt="" />
    <div class="text">
      <!-- <span v-html="contents.content"></span> -->
      <p>
        河南省第二人民医院（河南医学高等专科学校附属医院）是一所集医疗、教学、科研、康复和预防保健为一体的三级综合医院。2011年10月16日开诊运行。
      </p>
      <p>
        医院地处郑州南龙湖宜居教育园区，毗邻107国道、京港澳高速、绕城高速、京沙快速路、郑新快速路、地铁2号线等干线，周边山水环抱、天蓝水碧，环境优美宜业宜居。
      </p>
      <p>
        我院规划用地300亩，总体规划床位2000张，其中一期建筑面积56000余平方米，现开放床位820张，现开设急诊科、内科、外科、妇产科、儿科、NICU、肿瘤科、五官科、ICU、皮肤科、中医科、康复医学科、中西医结合不孕不育科、麻醉科、手术室、检验科、放射科、病理科、功能监察科等科室；配备有GE256排512层CT、飞利浦DSA、西门子3.0T、1.5T核磁共振、螺旋CT、DR等诊疗设备。
      </p>
      <p>
        现有职工近700人，其中高级职称80余人，中级职称130余人；博士硕士60余人。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "hospitalProfile",
  data() {
    return {
      contents: "",
    };
  },
  methods: {
    getData() {
      let postData = {
        parentId: this.$route.query.id,
      };
      this.$http.get("/api/content/infoByParentId", postData).then((res) => {
        this.contents = res.data.contents.find(
          (item) => item.title == "医院简介"
        );
        console.log(res.data);
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.text {
  font-size: 0.45rem;
  line-height: 30px;
  text-align: left;
  text-indent: 0.9rem;
  padding: 0.2rem;
  color: rgba(87, 89, 101, 1);
  font-family: "Source Han Sans CN";
}
.body p {
  margin: 0.2rem;
}
.body img {
  width: 10rem;
  height: 7rem;
}
</style>
